import './App.css';
import HomePage from './Pages/HomePage/HomePage';
import Work from './Pages/Work/Work';
import Contact from './Pages/Contact/Contact';
import About from './Pages/About/About';
import Links from './Pages/Links/Links';
import Game from './Pages/Game/Game';
import Resume from './Pages/Resume/Resume';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/Work" element={<Work />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/About" element={<About />} />
      <Route path='/Links' element={<Links />} />
      <Route path='/Game' element={<Game />} />
      <Route path='/Resume' element={<Resume/>} />
      </Routes>

      </BrowserRouter>
      
     
    </div>
  );
}

export default App;
