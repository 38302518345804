import "./About.scss";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function About() {

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      }
       else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);
  reveal();
  

// gsap.registerPlugin(ScrollTrigger);
// // REVEAL //
// gsap.utils.toArray("#revealUp").forEach(function (elem) {
//   ScrollTrigger.create({
//     trigger: elem,
//     start: "top 90%",
//     end: "bottom 10%",
//     markers: false,
//     onEnter: function () {
//       gsap.fromTo(
//         elem,
//         { y: 100, autoAlpha: 0 },
//         {
//           duration: 1.25,
//           y: 0,
//           autoAlpha: 1,
//           ease: "back",
//           overwrite: "auto"
//         }
//       );
//     },
//     onLeave: function () {
//       gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
//     },
//     onEnterBack: function () {
//       gsap.fromTo(
//         elem,
//         { y: -100, autoAlpha: 0 },
//         {
//           duration: 1.25,
//           y: 0,
//           autoAlpha: 1,
//           ease: "back",
//           overwrite: "auto"
//         }
//       );
//     },
//     onLeaveBack: function () {
//       gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
//     }
//   });
// });




  return (
    <div className="About">
      <h1 className="About__title reveal">About me</h1>
      <div className="About__first-text">
        <p className="About__colorful1 reveal fade-bottom" >
          Hi, I am Ana! Good to see you here. I am a Full-Stack Web Developer,
          Art enthusiast, baroque architecture fan and aesthete based out of Vancouver, BC
        </p>
        <p className="About__main1 reveal fade-bottom" >
          My coding journey started in college when I took my first introductory computer science class in Python while studying actuarial science.
          Not long after I got my first JavaScript book (JavaScript&jQuery by Jon Duckett) and I made my first Tic Tac Toe game. 
    
        </p>
      </div>

      <div className="About__second-text" >
        <p className="About__main2 reveal fade-bottom">
        The satisfaction of creating something from scratch and seeing it come to life through lines of code written by me
         made me fall in love. I am Skilled at | HTML5 | CSS3 | JAVASCRIPT | REACT | Mysql
         but still exploring other technologies and frameworks that catch my interest.
         I offer both design and development services of web applications.
        </p>
        <p className="About__colorful2 reveal fade-bottom">
        if you're looking for a developer to add to your team, I'd love to hear from you!

        </p>
      </div>

      <div className="About__third-text">
        <p className="About__colorful3"></p>
        <p className="About__main3"></p>
      </div>
    </div>
  );
}

export default About;
