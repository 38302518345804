import "./Resume.scss";
import qr from '../../Assets/Images/resu.png';
import resu from '../../Assets/resu.pdf';

const Resume = () => {
  return <div className="Resume">
    <h1 className="Resume-title">My Resume</h1>
    <p className="Resume-instruct">Scan the QR code below to open the PDF or download it 
    <a
        href={resu}
        download="Ana_PDF"
        target="_blank"
        rel="noreferrer"
      className="spn"> here</a>
    </p>
    <img src={qr} alt='QR Code' className="Resume-img"/>
  </div>;
};

export default Resume;
