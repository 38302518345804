import "./HomePage.scss";

import NavBar from "../../Pages/NavBar/NavBar"
import Work from "../../Pages/Work/Work";
import Contact from "../../Pages/Contact/Contact";
import About  from "../../Pages/About/About";
import Resume from "../../Pages/Resume/Resume";
import Footer from "../../Pages/Footer/Footer";

function HomePage() {
  const TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 3000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  };

  TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 600;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };


  window.onload = function () {
    var elements = document.getElementsByClassName("typewrite");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-type");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period);
      } 
    }
    const css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
  };
  
  return (
    <div className="HomePage">
      <div className="HomePage__hero">
        <div className="HomePage__hero-animation">
          <a
            id="anim"
            className="HomePage__anim-typewriter"
            href="#"
            class="typewrite"
            data-period="3000"
            data-type='[ "Hi, I am Ana.", "I build stuff for the Web." ]'
          >
            <span className="wrap"></span>
          </a>
        </div>
      <NavBar />
      <div className="HomePage__about-block" id="about">
        <About />
      </div>
      <div className="HomePage__work-block" id='work'> 
      <Work />
      </div>
      <div className="HomePage__contact-block" id='contact'> 
      <Contact />
      </div>
      <div className="HomePage__contact-block" id='resume'> 
      <Resume />
      </div>
      <div className="HomePage__contact-block" id='footer'> 
      <Footer />
      </div>
    </div>
  </div>
  
  );
}

export default HomePage;
