import './NavBar.scss';
import {Link} from 'react-scroll'
import paper from "../../Assets/Icons/paper.svg";
import phone from "../../Assets/Icons/phone.svg";
import question from "../../Assets/Icons/question.svg";
import res from "../../Assets/Icons/res.svg";


function NavBar() {
    return(
        <div className='NavBar'>
             <main className="NavBar__info-block">
        <Link to="work" smooth={true} className="NavBar__first-block  NavBar__link">
          {/* <Link smooth={true} to="work" className="NavBar__link">  */}
            <p className="NavBar__text1">Work</p>
            <img className="NavBar__icon" src={paper} alt="paper icon" />
         
          {/* </Link>   */}
        </Link>
        <div className="NavBar__blocks-container">
          <div className="NavBar__top-block">
      
            <Link to="contact" smooth={true} className="NavBar__second-block NavBar__link ">
            {/* <Link to="contact" smooth={true} className="NavBar__link"> */}
            
              <p className="NavBar__text">Contact</p>
              <img className="NavBar__icon" src={phone} alt="phone icon" />

            {/* </Link> */}
          </Link>

            <div className="NavBar__fourth-block">
            <Link to="resume" smooth={true} className="NavBar__link">
            <p className="NavBar__text">Resume</p>
            <img
                className="NavBar__icon"
                src={res}
                alt="resume icon"
              />

            </Link>
            </div>
          </div>
          <div className="NavBar__bottom-block">
            <div className="NavBar__third-block">
          <Link to="about" className="NavBar__link">
              
          </Link>
            </div>
            <Link smooth={true} to="about"  className="NavBar__fifth-block NavBar__link">
          {/* <Link smooth={true} to="about" className="NavBar__link"> */}
              <p className="NavBar__text">About</p>
              <img
                className="NavBar__icon"
                src={question}
                alt="question icon"
              />
          {/* </Link> */}
            </Link>
          </div>
        </div>
      </main>

        </div>
    )
}

export default NavBar;