import "./Contact.scss";
import { send } from "emailjs-com";
import linkedin from "../../Assets/Icons/linkedin.svg";
import { useState } from "react";
import $ from 'jquery';
import swal from "sweetalert";


function Contact() {
  const [toSend, setToSend] = useState({
    to_name: "",
    message: "",
    reply_to: "",
  });

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
 
  };
  const onSubmit = (e) => {
    e.preventDefault();
    send("service_wwtxrso", "template_rslx36i", toSend, "nub-CGkWseQIxbxTK")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
     
  };
  const handleClick = (e) => {
    e.preventDefault();
    $.getScript('https://unpkg.com/sweetalert/dist/sweetalert.min.js', function() {
    swal({
      customClass: {
        title: 'swal-title', 
      },
      title: "I've got your message!",
      text: "I will get back to you ASAP ",
      icon: "success",
      button: "Go back",
    }).then( () => {
      window.location.href = "/";
  })
});
}

  return (
    <div className="Contact">
      <h1 className="Contact__title">Contact</h1>
      <div className="Contact__main">
        <div className="Contact__right-side">
          <div className="Contact__top">
            <p className="Contact__top-text">
              let's talk!
            </p>
          
          </div>
          <div className="Contact__bottom">
            <div className="Contact__bottom-text">
              <div>
                <a href="https://www.linkedin.com/in/anaribaz/">
                  <img
                    className="Contact__icons"
                    src={linkedin}
                    alt="linkedin logo"
                  />
                </a>
                <a href="https://github.com/anaribazz">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="Contact__left-side">
          <div className="Contact__single-block">
            <form className="Contact__form" onSubmit={onSubmit}>
              <input required='require'
                className="Contact__name"
                type="text"
                name="from_name"
                placeholder="Your Name"
                value={toSend.from_name}
                onChange={handleChange}
              />
               <input required='require'
                className="Contact__name"
                type="text"
                name="reply_to"
                placeholder="Your email"
                value={toSend.reply_to}
                onChange={handleChange}
              />
              <textarea required='require'
                className="Contact__text"
                type="text"
                name="message"
                placeholder="Your message"
                value={toSend.message}
                onChange={handleChange}
              ></textarea>
             
              <button onClick={handleClick} className="Contact__button" lue="Submit" type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
