import "./Footer.scss";

const Footer = () => {
  return (
    <div className="Footer">
      <p className="Footer-desc">Created with love and React.JS</p>
      <div className="Footer-box">
        <p className="Footer-desc">Designed by © Ana Ribas 2023</p>
        <p className="Footer-desc">anaribas@proton.me</p>
      </div>
    </div>
  );
};

export default Footer;
