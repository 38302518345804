import './Game.scss';

function Game () {
    return(
        <div className='Game'>
           <h1 className='Game__title'>Have Fun!</h1>

        </div>

    )
}

export default Game;