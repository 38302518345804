import './Links.scss';

function Links () {
    return(
        <div className='Links'>
            <h1 className='Links__title'>Links.</h1>
            {/* probably some srticles */}

        </div>

    )
}

export default Links;