import "./Work.scss";
// import { Link } from "react-router-dom";
import bandsite from "../../Assets/Videos/coderama.mov";
import brainflix from "../../Assets/Images/paralela.png";
import housembly from "../../Assets/Images/house.png";
// import back from "../../Assets/Icons/back.svg";
// import HomePage from '../HomePage/HomePage';

function Work() {
  return (
    <div className="Work">
      <h1 className="Work__title">My Work</h1>

      <div className="Work__main-container">

        <div className="Work__sub">
          <div className="Work__card">
          <div className="Work__video-title">Coderama</div>
         <a href="https://fancy-naiad-c87cc5.netlify.app/"><video loop autoPlay=" " muted src={bandsite} className="video" id='small' /></a>
        </div>
        
          <div className="Work__video-info">
            <p className="Work__info-title"> Written in</p>
            <p className="Work__info-techs"> ReactJS</p>
            <p className="Work__info-techs"> Sass</p>
            <p className="Work__info-link">
              You can find the source code{" "}
              <a
                href="https://github.com/anaribazz"
                className="Work__github-link"
              >
                here
              </a>
            </p>
          </div>
        </div>
        <div className="Work__card">
          <div className="Work__video-title">Paralela I</div>
          <a href="https://clinquant-sunshine-cc95bf.netlify.app/"><img src={brainflix} alt="banner" className="video"/></a>
          <div className="Work__video-info">
            <p className="Work__info-title"> Written in</p>
            <div className="Work__info-top">
              <p className="Work__info-techs"> ReactJS</p>
              <p className="Work__info-techs">Express</p>
            </div>
            <p className="Work__info-techs"> Sass</p>
            <p className="Work__info-link">
              You can find the source code{" "}
              <a
                href="https://github.com/anaribazz"
                className="Work__github-link"
              >
                here
              </a>
            </p>
          </div>
        </div>
        <div className="Work__card">
          <div className="Work__video-title">Housembly</div>
          <a><img src={housembly} alt="banner" className="video"/></a>
          <div className="Work__video-info">
            <p className="Work__info-title"> Written in</p>
            <div className="Work__info-top">
              <p className="Work__info-techs"> ReactJS</p>
              <p className="Work__info-techs">Express</p>
            </div>
            <div className="Work__info-top">
              <p className="Work__info-techs"> Sass</p>
              <p className="Work__info-techs"> MySQL</p>
            </div>
            <p className="Work__info-link">
              You can find the source code{" "}
              <a
                href="https://github.com/anaribazz"
                className="Work__github-link"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="Work__back"> */}
        {/* <Link to="/">
          <img className="Work__back-img" src={back} alt="back arrow" />
        </Link> */}
      {/* </div> */}
    </div>
  );
}

export default Work;
